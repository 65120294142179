.trigger-wrapper[data-v-39368874] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: var(--rootFontSize);
  width: 100%;
}
.trigger-wrapper .trigger-btn-wrapper[data-v-39368874] {
    width: 100%;
    padding: 4px;
}
.trigger-wrapper .trigger-btn-wrapper .trigger-btn[data-v-39368874] {
      width: 100%;
      border-radius: 2px;
}
.trigger-wrapper .trigger-list[data-v-39368874] {
    width: 100%;
}
.trigger-wrapper .trigger-list .trigger-item[data-v-39368874] {
      width: 100%;
      height: 40px;
      padding: 4px 8px;
      border: 1px dotted #ccc;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.trigger-wrapper .trigger-list .trigger-item .trigger-item-name[data-v-39368874] {
        width: calc(100% - 80px);
        overflow: hidden;
}
.trigger-wrapper .trigger-list .trigger-item .trigger-item-btn[data-v-39368874] {
        width: 60px;
        text-align: right;
        font-size: var(--rootFontSize2);
}
.trigger-wrapper .trigger-list .trigger-item .trigger-item-btn .el-icon-edit[data-v-39368874] {
          color: #409eff;
          margin-right: 10px;
}
.trigger-wrapper .trigger-list .trigger-item .trigger-item-btn .el-icon-delete[data-v-39368874] {
          color: #f56c6c;
}
.trigger-form[data-v-39368874] {
  min-height: 400px;
}
.trigger-form .trigger-form-item .el-select[data-v-39368874] {
    width: 100%;
}
.trigger-form .cus-divider .el-divider__text[data-v-39368874] {
    color: #606266;
    font-weight: 500;
}
.trigger-form .add-btn-wrapper[data-v-39368874] {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px dotted #ccc;
    cursor: pointer;
}
.trigger-form .add-btn-wrapper i[data-v-39368874] {
      font-size: var(--rootFontSize3);
      color: #409eff;
      line-height: inherit;
}
