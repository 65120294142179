.config-box[data-v-031a9436] {
  height: 100%;
}
.config-box[data-v-031a9436] .el-tabs__nav {
    width: 100%;
    text-align: center;
}
.config-box[data-v-031a9436] .el-tabs__nav .el-tabs__item {
      width: 33.33%;
      padding: 0;
}
.config-box[data-v-031a9436] .el-tabs__nav .el-tabs__active-bar {
      width: 33.33%;
}
.config-box[data-v-031a9436] .el-tabs__content {
    height: calc(100% - 60px);
    overflow-y: scroll;
}
[data-v-031a9436] .el-dialog__wrapper {
  text-align: left;
}
[data-v-031a9436] .el-dialog__body {
  text-align: left !important;
}
[data-v-031a9436] .el-dialog__header {
  text-align: left;
  border-bottom: solid 1px #dcdfe6;
}
[data-v-031a9436] .el-tab-pane {
  padding-right: 15px;
}
[data-v-031a9436] .el-tabs__content {
  padding-left: 10px;
}
[data-v-031a9436] .el-collapse-item__header {
  background-color: #f1f6fc;
  padding-left: 8px;
}
[data-v-031a9436] .el-collapse-item__content {
  padding: 20px 0 10px;
}
[data-v-031a9436] .el-collapse {
  border-bottom: none;
}
.trigger[data-v-031a9436] .el-collapse-item__wrap {
  border-bottom: none;
}
