[data-v-b3860398] .CodeMirror {
  height: 700px !important;
  text-align: left !important;
}
[data-v-b3860398] .preview-form-dialog {
  height: 100%;
}
[data-v-b3860398] .preview-form-dialog .el-dialog__body {
    height: 100%;
}
