[data-v-69696cb5] .column-config-dlg .el-dialog__body {
  min-height: 300px;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container {
    width: 100%;
    height: 100%;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .column-config-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      border-top: 1px solid #f1f4f6;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background-color: #f5f7fa;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .column-config-header .column-config-header-item {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        text-align: left;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .column-config-tree {
      height: 300px;
      overflow-y: auto;
      width: 100%;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .column-config-tree .el-tree-node__content {
        height: auto;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .column-config-tree .column-config-row {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        border-top: 1px solid #f1f4f6;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .column-config-tree .column-config-row:first-of-type {
          border-top: none;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .column-config-tree .column-config-row .column-config-item {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          text-align: left;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .add-btn-wrapper {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px dotted #ccc;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      cursor: pointer;
      margin-top: 14px;
}
[data-v-69696cb5] .column-config-dlg .el-dialog__body .column-config-container .add-btn-wrapper i {
        font-size: var(--rootFontSize3);
        color: #409eff;
        line-height: inherit;
}
